jQuery(document).ready(function($) {
  require('./components/mobile-header.js');
  require('./components/video.js');
  require('./components/slider.js');


  loadMorePosts.init();


  //Scroll Active
  jQuery(window).on('scroll', function() {
    if (1 < window.pageYOffset) {
      jQuery('header').addClass('scroll-active');
    } else {
      jQuery('header').removeClass('scroll-active');
    }
  });

  jQuery(window).on('load', function() {
    if (1 < window.pageYOffset) {
      jQuery('header').addClass('scroll-active');
    } else {
      jQuery('header').removeClass('scroll-active');
    }
  });


  // Case Study Filter Dropdown
  jQuery(function($) {
    $('.filter-dropdown').click(function() {
      var accordionContent = $('.archive-categories')
      var accordionContentHeight = accordionContent[0].scrollHeight
      console.log(accordionContentHeight);
  
      $(this).toggleClass('active')
  
      if($(this).hasClass('active')) {
        accordionContent.css('max-height', `${accordionContentHeight}px`)
      } else {
        accordionContent.css('max-height', `0px`)
      }
    })
  })


  // Animate Year Box
  var animatedSections = jQuery('.animated-year');
  var windowHeight = window.innerHeight;

  if (animatedSections.length > 0) {
    animatedSections.each(function () {
      var section = jQuery(this);
      var offsetTop = section.offset().top;

      var yearBox = $(section).next('.year-box')
      var yearBoxHeight = yearBox[0].scrollHeight

      if (window.pageYOffset >= offsetTop - windowHeight / 1.5) {
        jQuery(section).addClass('year-active');

        if($(this).hasClass('year-active')) {
          yearBox.css('max-height', `${yearBoxHeight}px`)
          yearBox.css('opacity', '1')
        }
      }
    });

    window.addEventListener('scroll', function () {
      animatedSections.each(function () {
        var section = jQuery(this);
        var offsetTop = section.offset().top;

        var yearBox = $(section).next('.year-box')
        var yearBoxHeight = yearBox[0].scrollHeight

        if (window.pageYOffset >= offsetTop - windowHeight / 1.5) {
          jQuery(section).addClass('year-active');

          if($(this).hasClass('year-active')) {
            yearBox.css('max-height', `${yearBoxHeight}px`)
            yearBox.css('opacity', '1')
          }
        }
      });
    });
  }
});


// Animated Section
var animatedSections = jQuery('.animated-section');
var windowHeight = window.innerHeight;

if(animatedSections.length > 0) {
  animatedSections.each(function () {
    var section = jQuery(this);
    var offsetTop = section.offset().top;

    if(window.pageYOffset >= offsetTop - windowHeight / 1.5) {
      jQuery(section).addClass('active');
    }
  });

  window.addEventListener('scroll', function () {
    animatedSections.each(function () {
      var section = jQuery(this);
      var offsetTop = section.offset().top;

      if(window.pageYOffset >= offsetTop - windowHeight / 1.5) {
        jQuery(section).addClass('active');
      }
    });
  });
}


// Filter Local Storage
window.addEventListener("load", function(){
  const caseStudyPage = window.location.pathname;

  if(caseStudyPage != '/client-case-studies/') {
    sessionStorage.clear();
  }
});


// Load More Posts
var loadMorePosts = {
	init: function() {
    jQuery('#load-more').click(function(){
      loadMorePosts.loadMore();
    });
	},

	loadMore: function() {
		var loadContainer = jQuery('#load-container');
		var postCount = jQuery('#load-container .post-thumbnail').length;
		var totalPosts = loadContainer.data('total');

		if(postCount >= totalPosts) {
			return;
		}

		var data = {
      action: 'load_more_posts',
      post_count: postCount,
      post_search: posts.s,
      current_taxonomy: posts.current_taxonomy,
      current_term: posts.current_term,
      current_child_term: posts.current_child_term,
		};

		jQuery.ajax({
			url: posts.ajaxurl,
			type: "POST",
			data: data,
			beforeSend: function () {

				// Request for posts

				jQuery('#load-more').addClass('loading');
			},
			complete: function () {

				// Request for posts received / response is being sent back to us

				jQuery('#load-more').removeClass('loading');
			},
			success: function(data) {

				// We get posts back / successful response

				if(data) {
          loadContainer.append(data);
				}

				postCount = jQuery('#load-container .post-thumbnail').length;

				if(postCount >= totalPosts) {
          jQuery('#load-more').replaceWith('<a class="button no-posts" href="#category-anchor">Back to top<span></span></a>');
				}

				return;
			}
		});
	}
}


// Number Counter
// How long you want the animation to take, in ms
const animationDuration = 2000;
// Calculate how long each ‘frame’ should last if we want to update the animation 60 times per second
const frameDuration = 1000 / 60;
// Use that to calculate how many frames we need to complete the animation
const totalFrames = Math.round( animationDuration / frameDuration );
// An ease-out function that slows the count as it progresses
const easeOutQuad = t => t * ( 2 - t );

// The animation function, which takes an Element
const animateCountUp = el => {
	let frame = 0;
	const countTo = parseInt( el.innerHTML, 10 );
	// Start the animation running 60 times per second
	const counter = setInterval( () => {
		frame++;
		// Calculate our progress as a value between 0 and 1
		// Pass that value to our easing function to get our
		// progress on a curve
		const progress = easeOutQuad( frame / totalFrames );
		// Use the progress value to calculate the current count
		const currentCount = Math.round( countTo * progress );

		// If the current count has changed, update the element
		if ( parseInt( el.innerHTML, 10 ) !== currentCount ) {
			el.innerHTML = currentCount;
		}

		// If we’ve reached our last frame, stop the animation
		if ( frame === totalFrames ) {
			clearInterval( counter );
		}
	}, frameDuration );
};

// Run the animation on all elements with a class of ‘countup’
const runAnimations = () => {
	const countupEls = document.querySelectorAll( '.countup' );
	countupEls.forEach( animateCountUp );
};

var animatedSections2 = jQuery('.animated-number');
var windowHeight2 = window.innerHeight;
var started = true;

if(animatedSections2.length > 0) {
  animatedSections2.each(function () {
    var section2 = jQuery(this);
    var offsetTop2 = section2.offset().top;

    if(window.pageYOffset >= offsetTop2 - windowHeight2 / 1.5) {
      runAnimations();

      started = false;
    }
  });

  window.addEventListener('scroll', function () {
    animatedSections2.each(function () {
      var section2 = jQuery(this);
      var offsetTop2 = section2.offset().top;

      if((window.pageYOffset >= offsetTop2 - windowHeight2 / 1.5) && started) {
        runAnimations();

        started = false;
      }
    });
  });
}