jQuery(function($) {
  $('.slider').each(function() {
    var slider = $(this)
    var config = {
      pageDots: slider.hasClass('has-dots'),
      prevNextButtons: slider.hasClass('has-arrows'),
      wrapAround: false,
      imagesLoaded: true,
      cellAlign: 'left',
      contain: false
    }

    slider.flickity(config)
  })

  $('.testimonials-slider').each(function() {
    var slider = $(this)
    var config = {
      pageDots: slider.hasClass('has-dots'),
      prevNextButtons: slider.hasClass('has-arrows'),
      wrapAround: true,
      imagesLoaded: true,
      contain: false
    }

    slider.flickity(config)
  })

  $('.logo-slider').each(function() {
    var logoSlider = $(this)
    var logoConfig = {
      pageDots: logoSlider.hasClass('has-dots'),
      prevNextButtons: logoSlider.hasClass('has-arrows'),
      wrapAround: true,
      imagesLoaded: true,
      autoPlay: 2500,
      cellAlign: 'left',
      draggable: false,
      contain: false
    }

    logoSlider.flickity(logoConfig)
  })
})